import { z } from "zod";

const querySchema = z.object({
  firstName: z.string().min(2).optional(),
  lastName: z.string().min(2).optional(),
  email: z.string().email().optional(),
  addressLine: z.string().min(5).optional(),
  state: z.string().min(2).optional(),
  city: z.string().min(2).optional(),
  country: z.string().length(2).optional(),
  zipCode: z.string().min(0).optional(),
  disableFirstName: z.boolean().optional(),
  disableLastName: z.boolean().optional(),
  disableCountry: z.boolean().optional(),
  disableEmail: z.boolean().optional(),
  disableAddressLine: z.boolean().optional(),
  disableState: z.boolean().optional(),
  disableCity: z.boolean().optional(),
  disableZipCode: z.boolean().optional(),
  metadata: z.record(z.string()).optional(),
});

export type QueryParams = z.infer<typeof querySchema>;

export const parseQueryParams = (
  searchParams: URLSearchParams
): QueryParams => {
  const params: Record<string, any> = {};
  const metadata: Record<string, string> = {};

  [
    "firstName",
    "lastName",
    "email",
    "addressLine",
    "state",
    "city",
    "country",
    "zipCode",

  ].forEach((field) => {
    const value = searchParams.get(field);
    if (value) {
      try {
        querySchema.shape[field as keyof typeof querySchema.shape].parse(value);
        params[field] = value;
      } catch (error) {
        console.warn(
          `Invalid value for ${field}:`,
          value,
          (error as Error).message
        );
      }
    }
  });

  [
    "firstName",
    "lastName",
    "email",
    "addressLine",
    "city",
    "state",
    "country",
    "zipCode",
  ].forEach((field) => {
    const disableKey = `disable${
      field.charAt(0).toUpperCase() + field.slice(1)
    }`;
    const value = searchParams.get(disableKey);
    if (value === "true") params[disableKey] = true;
  });

  searchParams.forEach((value, key) => {
    if (key.startsWith('metadata_')) {
      const metadataKey = key.replace('metadata_', '');
      metadata[metadataKey] = value;
    }
  });

  if (Object.keys(metadata).length > 0) {
    params.metadata = metadata;
  }

  return params;
};
