import React from "react";
import { TooltipRenderProps } from "react-joyride";


const CustomTooltip = ({
  continuous,
  step,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps,
  isLastStep,
}: TooltipRenderProps) => {
  return (
    <div
      {...tooltipProps}
      className="bg-background-secondary border border-border-secondary rounded-xl p-6 max-w-[380px] shadow-lg"
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          {step.data?.icon && <div className="text-4xl">{step.data.icon}</div>}
          <h2 className="text-2xl font-display font-medium text-text-primary">
            {step.title}
          </h2>
          <p className="text-text-secondary text-sm">{step.content}</p>
        </div>
        {!step?.data?.disableButtons && (
          <div className="flex items-center justify-between w-full gap-2">
            {continuous && !step.data?.disableSkip && (
              <button {...skipProps} className="w-full button-outline">
                Skip tour
              </button>
            )}
            {!step.data?.disableNext && (
              <button
                {...(continuous ? primaryProps : closeProps)}
                className="w-full button-primary"
              >
                {step.data?.buttonText || "Next"}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTooltip;
