import { MODE } from "../lib/constants/api";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  if (MODE === false) return null;
  return (
    <div className="w-[100vw] absolute  z-20 top-0 flex flex-col items-center">
      <div className="h-1 w-[100vw] hidden lg:block shadow-md bg-[#22272F] dark:bg-background-secondary "></div>
      <div className="lg:rounded-b-md shadow-md font-medium text-sm font-body h-fit w-full lg:w-fit bg-[#22272F] dark:bg-background-secondary text-center  text-white px-3 py-2 pt-1">
        {t('banner.testMode')}
        <a
          href="https://docs.dodopayments.com/miscellaneous/test-mode-vs-live-mode"
          target="_blank"
          rel="noreferrer"
          className="ml-2 underline"
        >
          {t('banner.learnMore')}
        </a>
      </div>
    </div>
  );
};

export default Banner;
