import { COUNTRIES } from "../../components/ui/CountrySelector/countries";
import { ProductData } from "../../types/types";
import { API_BASE_URL, INTERNAL_API_BASE_URL } from "../constants/api";

interface ProductDataCheckout {
  business_id: string;
  is_recurring: boolean;
  price_after_disount: number;
  pay_what_you_want: boolean;
  payment_frequency_interval: string;
  payment_frequency_count: number;
  trial_period_days: number;
  discount: number;
  price: number;
  suggested_amount?: number;
  product_name: string;
  product_image: string;
  product_description: string;
  currency: string;
  product_id: string;
  tax_inclusive: boolean;
}

export interface PaymentData {
  [key: string]: any;
}
export const fetchProductDetails = async (id: string): Promise<ProductData> => {
  try {
    const response = await fetch(`${API_BASE_URL}/checkout/${id}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data as ProductData;
  } catch (error) {
    console.error("An error occurred while fetching product details:", error);
    throw error;
  }
};

export const fetchProductDetailsCheckout = async (
  product_id: string
): Promise<ProductDataCheckout> => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/checkout/static/${product_id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok) {
      const error = new Error(`HTTP error! status: ${response.status}`) as Error & { statusCode: number };
      error.statusCode = response.status;
      throw error;
    }

    const data = await response.json();
    return data as ProductDataCheckout;
  } catch (error) {
    console.error("An error occurred while fetching product details:", error);
    throw error;
  }
};
export const fetchBusinessName = async (
  business_id: string
): Promise<{
  name: string;
  image: string;
}> => {
  try {
    const response = await fetch(
      `${INTERNAL_API_BASE_URL}/checkout/businesses/${business_id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("An error occurred while fetching business name:", error);
    throw error;
  }
};

export const createPaymentLink = async (
  paymentData: PaymentData
): Promise<string> => {
  try {
    const response = await fetch(`${API_BASE_URL}/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentData),
    });
    if (response.status === 200) {
      const data = await response.json();
      return data.payment_link.slice(-8);
    }

    if (!response.ok) {
      const errorMessage = await response.text();
      if (errorMessage.includes("Invalid Phone number")) {
        throw new Error("Invalid Phone number");
      }
      throw new Error(errorMessage || "Failed to create payment link");
    }

    const errorMessage = "An unexpected error occurred";
    throw new Error(errorMessage);
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "An unexpected error occurred";

    throw new Error(errorMessage);
  }
};

function getMatchedCountries(
  countryValues: string[],
  countryObjects: ReadonlyArray<{ title: string; value: string }>
) {
  return countryObjects.reduce((acc, country) => {
    if (countryValues.includes(country.value)) {
      acc.push({ ...country });
    }
    return acc;
  }, [] as { title: string; value: string }[]);
}

export const fetchCountries = async () => {
  try {
    const response = await fetch(
      `${INTERNAL_API_BASE_URL}/checkout/supported_countries`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return getMatchedCountries(data, COUNTRIES);
  } catch (error) {
    console.error("An error occurred while fetching countries:", error);
    throw error;
  }
};
