import React, { useEffect, useState, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/Avatar";
import ThemeSwitch from "./ui/ThemeSwitch";
import Footer from "./ui/Footer";
import { fetchBusinessName } from "../lib/api/apiServices";
import "../App.css";
import { Tooltip } from "./ui/Tooltip";
import { Info } from "@phosphor-icons/react";
import {
  CurrencyCode,
  decodeCurrency,
  formatCurrency,
} from "../lib/utils/parsecurrency";
import PayWhatYouWant from "./PayWhatYouWant";
import LanguageSwitch from "./ui/LanguageSwitch";

// Types
export interface Product {
  is_recurring: boolean;
  price_after_disount: number;
  pay_what_you_want: boolean;
  payment_frequency_interval: string;
  payment_frequency_count: number;
  trial_period_days: number;
  suggested_price?: number;
  discount: number;
  price: number;
  tax_inclusive: boolean;
  product_name: string;
  product_image?: string;
  product_description: string;
}

interface CheckoutProductInfoProps {
  amount: number | undefined;
  setAmount: (amount: number) => void;
  productData: Product & {
    currency: string;
    business_id: string;
  };
}

const ProductImage: React.FC<{
  name: string;
  image: string;
  quantity: number;
}> = ({ name, image }) => (
  <div className="aspect-square rounded-lg w-[120px] h-[120px] relative">
    <img alt={name} src={image} className="object-cover rounded-lg" />
  </div>
);

const PriceDisplay = memo(
  ({
    price,
    color,
    currency,
    showTaxInfo = false,
  }: {
    price: number;
    currency: CurrencyCode;
    color?: string;
    showTaxInfo?: boolean;
  }) => {
    const { t } = useTranslation();

    const formattedPrice = useMemo(
      () => formatCurrency(decodeCurrency(price, currency), currency, false),
      [price, currency]
    );

    return (
      <span
        className={`font-medium font-body ${
          color || "text-text-primary"
        } text-sm md:text-base`}
      >
        {formattedPrice}
        {showTaxInfo && (
          <span className="text-text-secondary text-sm text-nowrap">
            {" "}
            {t("product.inclTaxes")}
          </span>
        )}
      </span>
    );
  }
);

const RecurringPrice: React.FC<{
  product: Product;
  currency: string;
  tax_inclusive: boolean;
}> = ({ product, currency, tax_inclusive }) => {
  const { t } = useTranslation();

  const getIntervalText = () => {
    const { payment_frequency_count, payment_frequency_interval } = product;
    return t(`product.interval.${payment_frequency_interval.toLowerCase()}`, {
      count: payment_frequency_count,
    });
  };

  return (
    <div className="flex flex-col text-nowrap items-start lg:items-end gap-1 w-fit">
      <div className="flex items-start lg:items-center flex-col lg:flex-row justify-end lg:gap-2">
        {product.discount > 0 && (
          <span className="line-through text-start w-full lg:w-fit !text-text-secondary text-sm md:text-base">
            <PriceDisplay
              color="text-text-secondary"
              price={product.price}
              currency={currency as CurrencyCode}
            />
          </span>
        )}
        <span className="font-medium font-body text-text-primary text-sm md:text-base">
          <PriceDisplay
            price={product.price_after_disount}
            currency={currency as CurrencyCode}
          />
          {getIntervalText()}
          {tax_inclusive && (
            <span className="text-text-secondary text-sm text-nowrap">
              {" "}
              {t("product.inclTaxes")}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

const OneTimePrice: React.FC<{
  amount: number | undefined;
  setAmount: (amount: number) => void;
  product: Product;
  currency: string;
  tax_inclusive: boolean;
  quantity: number;
}> = ({ amount, setAmount, product, currency, tax_inclusive, quantity }) => {
  React.useEffect(() => {
    if (product.pay_what_you_want && amount === undefined) {
      setAmount(amount || product.price);
    }
  }, [product, amount, setAmount]);

  if (product.pay_what_you_want) {
    return (
      <div className="flex items-center gap-2">
        <PriceDisplay
          price={amount || product.price}
          currency={currency as CurrencyCode}
          showTaxInfo={tax_inclusive}
        />
        <PayWhatYouWant
          setAmount={setAmount}
          quantity={quantity}
          product={product}
          currency={currency}
          showByDefault={true}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row items-center gap-1 text-nowrap">
      {product.discount > 0 && (
        <span className="line-through w-full lg:w-fit !text-text-secondary">
          <PriceDisplay
            color="text-text-secondary"
            price={product.price}
            currency={currency as CurrencyCode}
          />
        </span>
      )}
      <PriceDisplay
        price={product.price_after_disount}
        currency={currency as CurrencyCode}
        showTaxInfo={tax_inclusive}
      />
    </div>
  );
};

const ProductCard: React.FC<{
  amount?: number;
  setAmount: (amount: number) => void;
  product: Product;
  currency: string;
  quantity: number;
  tax_inclusive: boolean;
}> = ({ amount, setAmount, product, currency, quantity, tax_inclusive }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 items-start">
      <div className="flex gap-4 lg:gap-6 w-full items-start">
        <ProductImage
          name={product.product_name}
          image={product.product_image || "/placeholder.png"}
          quantity={quantity}
        />
        <div className="flex lg:flex-row flex-col items-start justify-between w-full">
          <div className="flex flex-col w-full gap-2">
            <h2 className="font-display text-xl font-medium text-text-primary">
              {product.product_name}
            </h2>
            <span className="text-text-primary font-medium text-sm">
              {t("product.quantity", { count: quantity })}
            </span>
            <p className="text-text-secondary break-words max-w-[25vw] hidden lg:block text-sm font-normal text-wrap">
              {product.product_description}
            </p>
          </div>
          {product.is_recurring ? (
            <RecurringPrice
              product={product}
              currency={currency}
              tax_inclusive={tax_inclusive}
            />
          ) : (
            <OneTimePrice
              amount={amount}
              setAmount={setAmount}
              product={product}
              quantity={quantity}
              currency={currency}
              tax_inclusive={tax_inclusive}
            />
          )}
        </div>
      </div>
      <p className="text-text-secondary break-words max-w-[60vw] lg:hidden text-sm font-normal text-wrap">
        {product.product_description}
      </p>
    </div>
  );
};

const usePriceCalculations = (
  product: Product,
  quantity: number,
  amount: number | undefined
): {
  displaySubtotal: number;
  displayTotal: number;
  discountAmount: number;
} => {
  return useMemo(
    () => ({
      displaySubtotal: product.pay_what_you_want
        ? (amount || product.price_after_disount) * quantity
        : product.price * quantity,
      displayTotal: product.pay_what_you_want
        ? (amount || product.price_after_disount) * quantity
        : product.price_after_disount * quantity,
      discountAmount: (product.price - product.price_after_disount) * quantity,
    }),
    [product, quantity, amount]
  );
};

const PriceSummary = memo(
  ({
    subtotal,
    currency,
    quantity,
    trial,
    isRecurring,
    product,
  }: {
    subtotal: number;
    currency: string;
    quantity: number;
    trial?: number;
    isRecurring: boolean;
    product: Product & { currency: string; business_id: string };
  }) => {
    const { t } = useTranslation();
    const { displaySubtotal, displayTotal, discountAmount } =
      usePriceCalculations(product, quantity, subtotal);

    const firstPaymentDate = useMemo(() => {
      if (!trial) return null;
      const today = new Date();
      return new Date(
        today.getTime() + trial * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    }, [trial]);

    return (
      <>
        <div className="flex flex-col border-t py-4 border-border-primary font-normal text-sm text-text-secondary pb-4 gap-4">
          {isRecurring && trial !== undefined && trial > 0 && (
            <div className="flex items-center justify-between">
              <span>{t("checkout.firstPaymentDate")}</span>
              <span>{firstPaymentDate}</span>
            </div>
          )}

          <div className="flex items-center justify-between">
            <span>{t("orderSummary.subtotal")}</span>
            <span>
              {formatCurrency(
                decodeCurrency(displaySubtotal, currency as CurrencyCode),
                currency as CurrencyCode,
                false
              )}
            </span>
          </div>
          {product.discount > 0 && !product.pay_what_you_want && (
            <div className="flex items-center justify-between">
              <span>{t("checkout.discountAmount")}</span>
              <span>
                {formatCurrency(
                  decodeCurrency(discountAmount, currency as CurrencyCode),
                  currency as CurrencyCode,
                  false
                )}
                {` (${product.discount}%)`}
              </span>
            </div>
          )}

          <div className="flex items-center justify-between">
            <span>{t("orderSummary.tax")}</span>
            <span>{t("checkout.taxCalculated")}</span>
          </div>
        </div>
        <div className="flex border-y py-4 border-border-primary text-base font-medium text-text-primary items-center justify-between">
          <span>
            {isRecurring && trial !== undefined && trial > 0
              ? "Amount due on First Payment"
              : t("orderSummary.total")}
          </span>
          <span>
            {formatCurrency(
              decodeCurrency(displayTotal, currency as CurrencyCode),
              currency as CurrencyCode,
              false
            )}
          </span>
        </div>
      </>
    );
  }
);

const CheckoutProductInfo: React.FC<CheckoutProductInfoProps> = memo(
  ({ productData, amount, setAmount }) => {
    const [searchParams] = useSearchParams();
    const [businessName, setBusinessName] = useState<{
      name: string;
      image: string;
    }>({ name: "", image: "" });
    const { t } = useTranslation();

    const quantity = useMemo(
      () => Math.max(parseInt(searchParams.get("quantity") || "1", 10), 1),
      [searchParams]
    );

    const refreshImage = useMemo(
      () => (searchParams.get("refresh") || "false") === "true",
      [searchParams]
    );

    const random = useMemo(
      () => (refreshImage ? Math.floor(Math.random() * 1000) : undefined),
      [refreshImage]
    );

    const formattedBusinessName = useMemo(
      () =>
        businessName.name
          ? t("checkout.businessCheckout", {
              business:
                businessName.name.charAt(0).toUpperCase() +
                businessName.name.slice(1),
            })
          : "Dodopayments - Checkout",
      [businessName.name, t]
    );

    const subtotal = useMemo(
      () =>
        productData.pay_what_you_want
          ? (amount || productData.price_after_disount) * quantity
          : productData.price_after_disount * quantity,
      [productData, amount, quantity]
    );

    useEffect(() => {
      if (amount === undefined) {
        setAmount(productData.price);
      }
    }, [productData.price, amount, setAmount]);

    useEffect(() => {
      const getBusinessName = async () => {
        try {
          const data = await fetchBusinessName(productData.business_id);
          setBusinessName(data);
        } catch (error) {
          console.error("Error loading product details:", error);
        }
      };
      getBusinessName();
    }, [productData.business_id]);

    if (!productData) {
      return <div>{t("checkout.noProductData")}</div>;
    }

    return (
      <section className="lg:h-screen flex flex-col lg:overflow-hidden">
        <Helmet>
          <title>{formattedBusinessName}</title>
        </Helmet>

        <header className="flex mx-4 lg:mx-8 pt-8 justify-between py-4 pb-2">
          <div className="text-text-primary flex items-center justify-between gap-2 text-xl pt-2 capitalize font-display font-medium">
            <Avatar>
              <AvatarImage
                src={businessName.image + `?refresh=${random}`}
                alt={businessName.name}
              />
              <AvatarFallback name={businessName.name} singleInitials={true} />
            </Avatar>
            <span>{businessName.name}</span>
          </div>
          <div className="flex items-center">
            <ThemeSwitch />
            <LanguageSwitch />
          </div>
        </header>

        <div className="flex-grow p-4 overflow-x-hidden lg:p-8 lg:pt-4 overflow-auto">
          <div className="flex flex-col gap-4 lg:gap-8 mb-8">
            <ProductCard
              amount={amount}
              setAmount={setAmount}
              product={productData}
              quantity={quantity}
              tax_inclusive={productData.tax_inclusive}
              currency={productData.currency}
            />
          </div>
          {productData.is_recurring &&
          productData.trial_period_days &&
          productData.trial_period_days > 0 ? (
            <div className="border-t font-medium py-2 text-text-primary  border-border-primary flex items-center justify-between">
              <div className="flex items-center gap-3">
                <span>{t("checkout.amountDueNow")}</span>
                <Tooltip
                  align="start"
                  content={
                    <ul className="list-disc pl-4">
                      <li>
                        {t("checkout.recurringPaymentInfo.mandateCreation")}
                      </li>
                      <li>{t("checkout.recurringPaymentInfo.nextPayment")}</li>
                      <li>{t("checkout.recurringPaymentInfo.taxVariation")}</li>
                      <li>{t("checkout.recurringPaymentInfo.invoice")}</li>
                    </ul>
                  }
                >
                  <Info className="w-5 h-5 cursor-pointer" />
                </Tooltip>
              </div>
              <div>
                {formatCurrency(0, productData.currency as CurrencyCode, false)}
              </div>
            </div>
          ) : null}

          <PriceSummary
            product={productData}
            subtotal={subtotal}
            quantity={quantity}
            currency={productData.currency}
            isRecurring={productData.is_recurring}
            trial={productData.trial_period_days}
          />
        </div>

        <div className="hidden pt-4 lg:block">
          <Footer />
        </div>
      </section>
    );
  }
);

export default memo(CheckoutProductInfo);
