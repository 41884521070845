import  { createContext, useContext, useState, useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const [enabled, setEnabled] = useState(theme === 'light' || false);

  const handleThemeChange = (enabled) => {
    setTheme(enabled ? 'light' : 'dark');
    setEnabled(enabled);
  };

  useEffect(() => {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, enabled, handleThemeChange }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
