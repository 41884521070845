import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslations from "./locales/en.json";
import esTranslations from "./locales/es.json";
import deTranslations from "./locales/de.json";
import frTranslations from "./locales/fr.json";
import arTranslations from "./locales/ar.json";
import caTranslations from "./locales/ca.json";
import cnTranslations from "./locales/cn.json";
import heTranslations from "./locales/he.json";
import itTranslations from "./locales/it.json";
import jaTranslations from "./locales/ja.json";
import nlTranslations from "./locales/nl.json";
import plTranslations from "./locales/pl.json";
import ptTranslations from "./locales/pt.json";
import svTranslations from "./locales/sv.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ["navigator", "querystring", "cookie", "localStorage", "htmlTag"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage", "cookie"],
    },
    resources: {
      en: {
        translation: enTranslations,
      },
      es: {
        translation: esTranslations,
      },
      de: {
        translation: deTranslations,
      },
      fr: {
        translation: frTranslations,
      },
      ar: {
        translation: arTranslations,
      },
      ca: {
        translation: caTranslations,
      },
      cn: {
        translation: cnTranslations,
      },
      he: {
        translation: heTranslations,
      },
      it: {
        translation: itTranslations,
      },
      ja: {
        translation: jaTranslations,
      },
      nl: {
        translation: nlTranslations,
      },
      pl: {
        translation: plTranslations,
      },
      pt: {
        translation: ptTranslations,
      },
      sv: {
        translation: svTranslations,
      },
    },
  });

export default i18n;
