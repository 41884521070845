import React, { useEffect, useMemo, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import ThemeSwitch from "./ui/ThemeSwitch";
import { Helmet } from "react-helmet";
import "../App.css";
import Footer from "./ui/Footer";
import { Product } from "../types/types";
import { fetchBusinessName } from "../lib/api/apiServices";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/Avatar";
import { Tooltip } from "./ui/Tooltip";
import { Info } from "@phosphor-icons/react";
import TestCard from "./ui/TestCard";
import { formatCurrency, decodeCurrency } from "../lib/utils/parsecurrency";
import { CurrencyCode } from "../lib/utils/parsecurrency";
import LanguageSwitch from "./ui/LanguageSwitch";

interface ProductCardProps {
  product: Product;
  isRecurring: boolean;
  billing_currency: string;
}

interface PriceSummaryItemProps {
  label: string;
  value: string;
}

interface ProductInfoProps {
  products: Product[];
  currency: string;
  business_id: string;
  tax: number;
  recurring_tax?: number;
  amount: number;
  isRecurring: boolean;
}

interface OrderSummary {
  subtotal: number;
  tax2: number;
  total: number;
}

const PriceDisplay = memo(({
  price,
  color,
  currency,
  showParentheses = false,
}: {
  price: number;
  currency: CurrencyCode;
  color?: string;
  showParentheses?: boolean;
}) => {
  const formattedPrice = useMemo(() => 
    formatCurrency(decodeCurrency(price, currency), currency, false),
    [price, currency]
  );

  return (
    <span className={`font-medium flex items-center gap-1 font-body ${color || "text-text-primary"} text-base`}>
      {showParentheses ? `(${formattedPrice})` : formattedPrice}
    </span>
  );
});

const ProductCard = memo(({
  product,
  isRecurring,
  billing_currency,
}: ProductCardProps) => {
  const { t } = useTranslation();

  const getIntervalText = () => {
    const { payment_frequency_count, payment_frequency_interval } = product;
    return t(`product.interval.${payment_frequency_interval?.toLowerCase()}`, {
      count: payment_frequency_count,
    });
  };

  const hasDiscount = product.og_price_before_discount !== product.og_price;

  const RecurringPrice = () => (
    <div className="flex flex-col text-nowrap items-start lg:items-end gap-1 w-fit">
      <div className="flex items-start lg:items-center flex-col lg:flex-row justify-end lg:gap-2">
        {hasDiscount && (
          <span className="line-through w-full lg:w-fit text-start text-text-secondary">
            <PriceDisplay
              color={"text-text-secondary"}
              price={product.og_price_before_discount}
              currency={product.og_currency as CurrencyCode}
            />
          </span>
        )}
        <span className="font-medium flex items-center gap-1 font-body text-text-primary text-base">
          <PriceDisplay
            price={product.og_price}
            currency={product.og_currency as CurrencyCode}
          />
          {getIntervalText()}
        </span>
      </div>
    </div>
  );

  const OneTimePrice = () => {
    const hasDiscount = product.og_price_before_discount !== product.og_price;
    const isDifferentCurrency = product.og_currency !== billing_currency;

    return (
      <div className="flex flex-col lg:flex-row items-center gap-1">
        {hasDiscount && (
          <span className="line-through w-full lg:w-fit !text-text-secondary">
            <PriceDisplay
              color="text-text-secondary"
              price={product.og_price_before_discount}
              currency={product.og_currency as CurrencyCode}
            />
          </span>
        )}

        {isDifferentCurrency ? (
          <div className="flex items-center gap-1">
            <PriceDisplay
              price={product.after_discount_price}
              currency={billing_currency as CurrencyCode}
            />
            <PriceDisplay
              price={product.og_price}
              currency={product.og_currency as CurrencyCode}
              showParentheses
            />
          </div>
        ) : (
          <PriceDisplay
            price={product.og_price}
            currency={product.og_currency as CurrencyCode}
          />
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-3 items-start">
      <div className="flex gap-4 lg:gap-6 w-full items-start">
        <div className="aspect-square rounded-lg w-[120px] h-[120px] relative">
          <img
            alt={product.name}
            src={product.image || "/placeholder.png"}
            className="object-cover rounded-lg"
          />
        </div>
        <div className="flex lg:flex-row flex-col items-start justify-between w-full">
          <div className="flex flex-col w-full gap-2">
            <h2 className="font-display text-xl font-medium text-text-primary">
              {product.name}
            </h2>
            <span className="text-text-primary font-medium text-sm">
              {t("productInfo.quantity", { count: product.quantity })}
            </span>
            <p className="text-text-secondary hidden break-words max-w-[25vw] lg:block text-sm font-normal text-wrap">
              {product.description}
            </p>
          </div>
          {isRecurring ? <RecurringPrice /> : <OneTimePrice />}
        </div>
      </div>
      <p className="text-text-secondary break-words max-w-[60vw] lg:hidden text-sm font-normal text-wrap">
        {product.description}
      </p>
    </div>
  );
});

const PriceSummaryItem = memo<PriceSummaryItemProps>(({
  label,
  value,
}) => (
  <div className="flex items-center justify-between">
    <span>{label}</span>
    <span>{value}</span>
  </div>
));

const ProductInfo: React.FC<ProductInfoProps> = memo(({
  products = [],
  currency = "USD",
  amount = 0,
  tax = 0,
  recurring_tax = 0,
  business_id,
  isRecurring = false,
}) => {
  const { t } = useTranslation();
  const [businessName, setBusinessName] = useState<{
    name: string;
    image: string;
  }>({ name: "", image: "" });

  const orderSummary = useMemo<OrderSummary>(() => {
    const tax2 = isRecurring ? recurring_tax : tax;
    const subtotal =
      isRecurring && !products[0]?.tax_inclusive ? amount : amount - tax2;
    const total =
      isRecurring && !products[0]?.tax_inclusive ? amount + tax2 : amount;
    return { subtotal, tax2, total };
  }, [amount, tax, isRecurring, recurring_tax, products]);

  const formattedBusinessName = useMemo(() => 
    businessName.name
      ? t("checkout.businessCheckout", {
          business: businessName.name.charAt(0).toUpperCase() + businessName.name.slice(1),
        })
      : "Dodopayments - Checkout",
    [businessName.name, t]
  );

  const firstPaymentDate = useMemo(() => {
    if (!isRecurring || !products[0]?.trial_period_days) return null;
    return new Date(
      new Date().getTime() +
        products[0].trial_period_days * 24 * 60 * 60 * 1000
    ).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  }, [isRecurring, products]);

  useEffect(() => {
    const GetBusinessName = async () => {
      try {
        const data = await fetchBusinessName(business_id || "");
        setBusinessName(data);
      } catch (error) {
        console.error("Error loading product details:", error);
      }
    };
    GetBusinessName();
  }, [business_id]);

  if (!products.length) {
    return <div>{t("productInfo.noProducts")}</div>;
  }

  return (
    <section className="lg:h-screen flex flex-col lg:overflow-hidden">
      <Helmet>
        <title>{formattedBusinessName}</title>
      </Helmet>
      <header className="flex mx-4 lg:mx-8  pt-8 justify-between py-4 ">
        <div className="text-text-primary flex items-center gap-2 text-2xl pt-2 capitalize font-display font-medium">
          <Avatar>
            <AvatarImage src={businessName.image} alt={businessName.name} />
            <AvatarFallback name={businessName.name} singleInitials={true} />
          </Avatar>
          <span>{businessName.name}</span>
        </div>
        <div className="flex items-center">
          <ThemeSwitch />
          <LanguageSwitch />
        </div>
      </header>

      <div className="flex-grow p-4 lg:p-8 lg:pt-2 overflow-auto">
        <div className="">
          <div className="flex flex-col gap-8 mb-8">
            {products.map((product) => (
              <ProductCard
                key={product.product_id}
                product={product}
                billing_currency={currency}
                isRecurring={isRecurring}
              />
            ))}
          </div>
          {isRecurring &&
          products[0].trial_period_days &&
          products[0].trial_period_days > 0 ? (
            <div className="border-t font-medium py-2 text-text-primary border-border-primary flex items-center justify-between">
              <div className="flex items-center gap-3">
                <span>{t("productInfo.amountDueNow")}</span>
                <Tooltip
                  align="start"
                  content={
                    <ul className="list-disc pl-4">
                      <li>
                        {t("productInfo.recurringPaymentInfo.mandateCreation")}
                      </li>
                      <li>
                        {t("productInfo.recurringPaymentInfo.nextPayment")}
                      </li>
                      <li>
                        {t("productInfo.recurringPaymentInfo.taxVariation")}
                      </li>
                      <li>{t("productInfo.recurringPaymentInfo.invoice")}</li>
                    </ul>
                  }
                >
                  <Info className="w-5 h-5 cursor-pointer" />
                </Tooltip>
              </div>
              <div>
                {formatCurrency(
                  0,
                  currency as CurrencyCode,
                  false
                )}
              </div>
            </div>
          ) : null}
          <div className="flex flex-col border-t py-4 border-border-primary font-normal text-sm text-text-secondary pb-4 gap-4">
            {isRecurring &&
            products[0]?.trial_period_days !== undefined &&
            products[0]?.trial_period_days > 0 &&
            firstPaymentDate !== null && (
              <PriceSummaryItem
                label={t("productInfo.firstPaymentDate")}
                value={firstPaymentDate}
              />
            )}
            <PriceSummaryItem
              label={t("orderSummary.subtotal")}
              value={formatCurrency(
                decodeCurrency(orderSummary.subtotal, currency as CurrencyCode),
                currency as CurrencyCode,
                false
              )}
            />

            <PriceSummaryItem
              label={t("orderSummary.tax")}
              value={formatCurrency(
                decodeCurrency(orderSummary.tax2, currency as CurrencyCode),
                currency as CurrencyCode,
                false
              )}
            />
          </div>

          <div className="flex border-y py-4 border-border-primary text-base font-medium text-text-primary items-center justify-between">
            <span>
              {isRecurring &&
              products[0].trial_period_days &&
              products[0].trial_period_days > 0
                ? "Amount due on First Payment"
                : t("orderSummary.total")}
            </span>
            <span>
              {formatCurrency(
                decodeCurrency(orderSummary.total, currency as CurrencyCode),
                currency as CurrencyCode,
                false
              )}
            </span>
          </div>

          <TestCard isINR={currency === "INR"} />
        </div>
      </div>

      <div className="hidden pt-4 lg:block">
        <Footer />
      </div>
    </section>
  );
});

export default memo(ProductInfo);
