// BuyPage.tsx
import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  fetchProductDetailsCheckout,
  createPaymentLink,
} from "../lib/api/apiServices";
import Footer from "../components/ui/Footer";
import CustomerForm from "../components/CustomerForm";
import LoadingOverlay from "../components/ui/Loading";
import CheckoutProductInfo from "../components/CheckoutProductInfo";
import { PaymentDetails } from "../types/types";
import Banner from "../components/banner";
import { toast, Toaster } from "sonner";
import { useTheme } from "../lib/ThemeContext";

interface ProductData {
  business_id: string;
  is_recurring: boolean;
  price_after_disount: number;
  pay_what_you_want: boolean;
  payment_frequency_interval: string;
  payment_frequency_count: number;
  trial_period_days: number;
  discount: number;
  price: number;
  product_name: string;
  product_image: string;
  product_description: string;
  currency: string;
  product_id: string;
  tax_inclusive: boolean;
}

// Custom hook for product data fetching
const useProductData = (productId: string | undefined) => {
  const [state, setState] = React.useState<{
    loading: boolean;
    productData: ProductData | null;
    error: Error | null;
    statusCode?: number;
  }>({
    loading: false,
    productData: null,
    error: null,
  });

  React.useEffect(() => {
    if (!productId) return;

    const fetchProduct = async () => {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        const data = await fetchProductDetailsCheckout(productId);
        setState((prev) => ({ ...prev, productData: data, loading: false }));
      } catch (error) {
        const statusCode =
          error instanceof Error && "statusCode" in error
            ? (error as any).statusCode
            : undefined;
        setState((prev) => ({
          ...prev,
          error: error as Error,
          statusCode,
          loading: false,
        }));
      }
    };

    fetchProduct();
  }, [productId]);

  return state;
};


const BuyPage: React.FC = () => {
  const { product_id } = useParams<{ product_id?: string }>();
  const { loading, productData, error, statusCode } =
    useProductData(product_id);
  const [searchParams] = useSearchParams();
  const [tour, setTour] = useState(
    searchParams.get("tour") === "true" || false
  );
  const { theme } = useTheme();
  const [amount, setAmount] = useState<number>();

  if (!product_id) {
    window.location.replace(`pdt_001/status/not-found`);
  }

  const handleError = React.useCallback((error: unknown) => {
    let errorMessage: string;
    
    if (error instanceof Error) {
      errorMessage = error.message.split("\n").join(" ");
    } else {
      errorMessage = "Failed to process payment. Please try again.";
    }

    toast.error(errorMessage);
  }, []);

  const handleSubmit = React.useCallback(
    async (
      paymentDetails: PaymentDetails,
      setFormLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      if (!productData?.business_id) {
        handleError(new Error("Business ID is required"));
        return;
      }

      try {
        setFormLoading(true);
        const paymentLink = await createPaymentLink({
          business_id: productData.business_id,
          payment_details: paymentDetails,
        });
        window.location.href = `/${paymentLink}${tour ? "?tour=true" : ""}`;
      } catch (error) {
        console.error("Error creating payment link:", error);
        handleError(error);
      } finally {
        setFormLoading(false);
      }
    },
    [productData, handleError, tour]
  );

  if (loading) return <LoadingOverlay />;

  if (error) {
    if (statusCode === 404 || error.message.includes("404")) {
      window.location.replace(`/${product_id}/status/not-found`);
    } else {
      window.location.replace(`/${product_id}/status/error`);
    }
  }

  return (
    <div className="app flex font-body lg:flex-row flex-col w-[100vw] h-screen">
      <Toaster position="top-right"  richColors theme={theme} />
      <Banner />
      <meta name="title" content="Dodo Checkout" />

      <section className="lg:w-1/2 lg:overflow-auto scrollable-element bg-background-primary lg:bg-background-secondary border-r border-border-secondary flex justify-between flex-col">
        {productData && (
          <CheckoutProductInfo
            amount={amount}
            setAmount={setAmount}
            productData={productData}
          />
        )}
      </section>

      <section className="flex lg:flex-row flex-col py-10 lg:min-h-screen lg:overflow-auto lg:w-1/2 justify-center items-center bg-background-primary">
        <div className="flex flex-col h-fit w-full lg:h-full items-center justify-center">
          {productData && (
            <CustomerForm
              tour={tour}
              setTour={setTour}
              amount={amount}
              productData={productData}
              onSubmit={handleSubmit}
            />
          )}
          <div className="block lg:hidden">
            <Footer />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BuyPage;
