import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { PhoneInput } from "react-international-phone";
import { useSearchParams } from "react-router-dom";
import { PaymentDetails } from "../types/types";
import { COUNTRIES } from "./ui/CountrySelector/countries";
import CountrySelector from "./ui/CountrySelector/selector";
import { SelectMenuOption } from "./ui/CountrySelector/types";
import Loading from "./ui/LoadingSpinner";
import "react-international-phone/style.css";
import "../styles/CustomerForm.css";
import { parseQueryParams } from "../lib/QuerryCollector";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import Joyride, { Step } from "react-joyride";
import CustomTooltip from "./CustomTooltip";
import { toast } from "sonner";

const formSchema = z.object({
  firstName: z.string().min(2, t("customerForm.firstName.error")),
  lastName: z.string().min(2, t("customerForm.lastName.error")),
  email: z.string().email(t("customerForm.email.error")),
  country: z.string().min(2, t("customerForm.country.error")),
  addressLine: z.string().min(5, t("customerForm.addressLine.error")),
  state: z.string().min(2, t("customerForm.state.error")),
  city: z.string().min(2, t("customerForm.city.error")),
  zipCode: z.string().min(2, t("customerForm.zipCode.error")),
  phoneNumber: z.string().optional(),
  taxId: z.string().optional(),
});

type FormData = z.infer<typeof formSchema>;

interface CustomerFormProps {
  productData: {
    is_recurring: boolean;
    product_id: string;
  };
  amount?: number;
  onSubmit: (
    paymentDetails: PaymentDetails,
    setFormLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  tour: boolean;
  setTour: React.Dispatch<React.SetStateAction<boolean>>;
}

const DEFAULT_COUNTRY = "US";

const isDesktop = () => window.innerWidth >= 1024;

const CustomerForm: React.FC<CustomerFormProps> = ({
  productData,
  amount,
  onSubmit,
  tour,
  setTour,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(DEFAULT_COUNTRY);
  const [run, setRun] = React.useState(false);
  const [showTaxId, setShowTaxId] = React.useState(false);

  const [phoneInputMeta, setPhoneInputMeta] = React.useState<{
    country: any;
    inputValue: string;
  } | null>(null);

  const queryParams = React.useMemo(
    () => parseQueryParams(searchParams),
    [searchParams]
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: queryParams.firstName || "",
      lastName: queryParams.lastName || "",
      email: queryParams.email || "",
      country: DEFAULT_COUNTRY,
      addressLine: queryParams.addressLine || "",
      state: queryParams.state || "",
      city: queryParams.city || "",
      zipCode: queryParams.zipCode || "",
      phoneNumber: "",
      taxId: "",
    },
  });

  useEffect(() => {
    try {
      window.history.pushState(null, "", window.location.href.split("?")[0]);
    } catch (error) {
      console.error("Failed to update URL:", error);
    }
  }, []);

  const phoneInputRef = useRef<any>(null);

  const createPaymentDetails = (formData: FormData): PaymentDetails => {
    const quantity = parseInt(searchParams.get("quantity") || "1", 10);
    const redirect_url = searchParams.get("redirect_url") || null;

    const billingInfo = {
      city: formData.city,
      country: formData.country,
      state: formData.state,
      street: formData.addressLine,
      zipcode: formData.zipCode,
    };

    const customerInfo = {
      email: formData.email,
      name: `${formData.firstName} ${formData.lastName}`,
      phone_number: formData.phoneNumber || null,
    };

    return productData.is_recurring
      ? {
          CreateSubscriptionRequest: {
            billing: billingInfo,
            customer: customerInfo,
            metadata: queryParams.metadata || undefined,
            payment_link: true,
            product_id: productData.product_id,
            quantity,
            return_url: redirect_url,
            tax_id: formData.taxId || null,
          },
        }
      : {
          CreateOneTimePaymentRequest: {
            billing: billingInfo,
            customer: customerInfo,
            metadata: queryParams.metadata || undefined,
            payment_link: true,
            product_cart: [
              {
                amount,
                product_id: productData.product_id,
                quantity,
              },
            ],
            return_url: redirect_url,
            tax_id: formData.taxId || null,
          },
        };
  };

  const handleFormSubmit = async (formData: FormData) => {
    setLoading(true);

    try {
      if (formData.phoneNumber && phoneInputMeta) {
        const phoneValue = phoneInputMeta.inputValue || "";
        const hasOnlyCountryCode = phoneInputMeta.country?.dialCode
          ? phoneValue.trim() === `+${phoneInputMeta.country.dialCode}`
          : false;

        if (hasOnlyCountryCode) {
          delete formData.phoneNumber;
        } else if (
          phoneInputMeta.country?.format &&
          phoneValue.length < phoneInputMeta.country.format.length
        ) {
          toast.error(t("customerForm.phoneNumber.error.incomplete"));
          return;
        }
      }

      const paymentDetails = createPaymentDetails(formData);
      await onSubmit(paymentDetails, setLoading);
    } catch (error) {
      toast.error(t("customerForm.errors.submission"));
      console.error("Form submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("country", selectedCountry);
  }, [selectedCountry, setValue]);

  useEffect(() => {
    const getCountry = async () => {
      try {
        const res = await fetch(`https://api.country.is/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        const data = await res.json();
        setSelectedCountry(data.country);
        setValue("country", data.country);

        if (phoneInputRef.current?.setCountry) {
          phoneInputRef.current.setCountry(data.country.toLowerCase());
        }
      } catch (error: any) {
        console.error("Failed to fetch country data:", error.message);
      }
    };

    if (!queryParams.country) {
      getCountry();
    } else {
      setSelectedCountry(queryParams.country);
      setValue("country", queryParams.country);
      if (phoneInputRef.current?.setCountry) {
        phoneInputRef.current.setCountry(queryParams.country.toLowerCase());
      }
    }
  }, [setValue, queryParams.country]);

  useEffect(() => {
    if (phoneInputRef.current?.setCountry) {
      phoneInputRef.current.setCountry(selectedCountry.toLowerCase());
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (tour && isDesktop()) {
      setTimeout(() => {
        setRun(true);
      }, 1000);
    }
  }, [tour]);

  const steps: Step[] = [
    {
      target: "#step1",
      title: "The customer enters details on this screen",
      content:
        "The fields are prefilled as an example. Click 'Continue to Payment' to proceed.",
      disableBeacon: true,
      placement: "left",
      data: {
        disableButtons: true,
        disableNext: true,
      },
    },
  ];

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="lg:p-8 p-4 transition-all duration-300 ease-in-out lg:px-[72px] max-w-2xl h-fit font-body w-full"
    >
      <Joyride
        run={run}
        tooltipComponent={CustomTooltip}
        steps={steps}
        continuous={true}
        styles={{
          options: {
            arrowColor: "var(--bg-secondary)",
            overlayColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 1000,
          },
        }}
      />
      <h2 className="text-base font-medium mb-3 font-display text-text-primary">
        {t("customerForm.title")}
      </h2>

      {/* Name Fields */}
      <div className="flex flex-col lg:flex-row gap-4 mb-4">
        <div className="flex-1">
          <label htmlFor="firstName" className="form-label">
            {t("customerForm.firstName.label")}{" "}
            <span className="text-red-500">*</span>
          </label>
          <input
            {...register("firstName")}
            type="text"
            id="firstName"
            placeholder={t("customerForm.firstName.placeholder")}
            className="form-input"
            disabled={queryParams.disableFirstName}
          />
          {errors.firstName && (
            <p className="text-red-600 text-sm mt-1">
              {errors.firstName.message}
            </p>
          )}
        </div>

        <div className="flex-1">
          <label htmlFor="lastName" className="form-label">
            {t("customerForm.lastName.label")}{" "}
            <span className="text-red-500">*</span>
          </label>
          <input
            {...register("lastName")}
            type="text"
            id="lastName"
            placeholder={t("customerForm.lastName.placeholder")}
            className="form-input"
            disabled={queryParams.disableLastName}
          />
          {errors.lastName && (
            <p className="text-red-600 text-sm mt-1">
              {errors.lastName.message}
            </p>
          )}
        </div>
      </div>

      {/* Email Field */}
      <div className="mb-4">
        <label htmlFor="email" className="form-label">
          {t("customerForm.email.label")}{" "}
          <span className="text-red-500">*</span>
        </label>
        <input
          {...register("email")}
          type="email"
          id="email"
          placeholder={t("customerForm.email.placeholder")}
          className="form-input"
          disabled={queryParams.disableEmail}
        />
        {errors.email && (
          <p className="text-red-600 text-sm mt-1">{errors.email.message}</p>
        )}
      </div>

      <div className="text-base font-medium pt-2 mb-3 font-display text-text-primary">
        {t("customerForm.billingAddress")}
      </div>

      {/* Country Selector */}
      <div className="mb-4">
        <label htmlFor="country" className="form-label">
          {t("customerForm.country.label")}{" "}
          <span className="text-red-500">*</span>
        </label>
        <Controller
          name="country"
          control={control}
          defaultValue={selectedCountry}
          render={({ field: { onChange } }) => (
            <CountrySelector
              id="country-selector"
              open={isOpen}
              onToggle={() => setIsOpen(!isOpen)}
              onChange={(val) => {
                setSelectedCountry(val);
                onChange(val);
              }}
              selectedValue={
                (COUNTRIES.find(
                  (option) => option.value === selectedCountry
                ) as SelectMenuOption) || {
                  title: "United States",
                  value: "US",
                }
              }
              disabled={queryParams.disableCountry}
            />
          )}
        />
        {errors.country && (
          <p className="text-red-600 text-sm mt-1">{errors.country.message}</p>
        )}
      </div>

      {/* Address Fields */}
      <div className="mb-4">
        <label htmlFor="addressLine" className="form-label">
          {t("customerForm.addressLine.label")}{" "}
          <span className="text-red-500">*</span>
        </label>
        <input
          {...register("addressLine")}
          type="text"
          id="addressLine"
          placeholder={t("customerForm.addressLine.placeholder")}
          className="form-input"
          disabled={queryParams.disableAddressLine}
        />
        {errors.addressLine && (
          <p className="text-red-600 text-sm mt-1">
            {errors.addressLine.message}
          </p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="state" className="form-label">
          {t("customerForm.state.label")}{" "}
          <span className="text-red-500">*</span>
        </label>
        <input
          {...register("state")}
          type="text"
          id="state"
          placeholder={t("customerForm.state.placeholder")}
          className="form-input"
          disabled={queryParams.disableState}
        />
        {errors.state && (
          <p className="text-red-600 text-sm mt-1">{errors.state.message}</p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-4 mb-4">
        <div className="flex-1">
          <label htmlFor="city" className="form-label">
            {t("customerForm.city.label")}{" "}
            <span className="text-red-500">*</span>
          </label>
          <input
            {...register("city")}
            type="text"
            id="city"
            placeholder={t("customerForm.city.placeholder")}
            className="form-input"
            disabled={queryParams.disableCity}
          />
          {errors.city && (
            <p className="text-red-600 text-sm mt-1">{errors.city.message}</p>
          )}
        </div>

        <div className="flex-1">
          <label className="form-label">
            {t("customerForm.zipCode.label")}{" "}
            <span className="text-red-500">*</span>
          </label>
          <input
            {...register("zipCode")}
            id="zipCode"
            placeholder={t("customerForm.zipCode.placeholder")}
            className="form-input"
            disabled={queryParams.disableZipCode}
          />
          {errors.zipCode && (
            <p className="text-red-600 text-sm mt-1">
              {errors.zipCode.message}
            </p>
          )}
        </div>
      </div>

      {/* Phone Number Field */}
      <div className=" w-full">
        <label htmlFor="phoneNumber" className="form-label">
          {t("customerForm.phoneNumber.label")}
        </label>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              ref={phoneInputRef}
              value={value}
              className="shadow-sm z-10"
              onChange={(phone, meta) => {
                onChange(phone);
                setPhoneInputMeta(meta);
              }}
              defaultCountry={selectedCountry.toLowerCase()}
              countrySelectorStyleProps={{
                flagClassName: "px-1",
                buttonClassName:
                  "border border-[#d0d5dd] rounded-l-lg px-2 bg-[#ffffff] dark:bg-[#0d0d0d] dark:border-[#323232] hover:bg-bg-secondary dark:hover:bg-[#1b1b1b]",
                dropdownStyleProps: {
                  listItemClassName:
                    "px-2 bg-[#ffffff] dark:bg-[#0d0d0d] hover:bg-bg-secondary dark:hover:bg-[#1b1b1b]",
                  className:
                    "rounded-lg bg-[#ffffff] border dark:border-[#323232] border-[#d0d5dd] dark:bg-[#0d0d0d] text-text-primary dark:text-[#ffffff]",
                },
              }}
              inputProps={{
                className:
                  "w-full font-body text-sm font-normal border border-[#d0d5dd] bg-[#ffffff] dark:bg-[#0d0d0d] dark:border-[#323232] px-2 rounded-r-lg text-text-primary dark:text-white " +
                  "focus:outline-none focus:ring-2 focus:ring-[#a6e500] dark:focus:ring-[#a6e500] focus:border-transparent",
              }}
            />
          )}
        />
        {errors.phoneNumber && (
          <p className="text-red-600 text-sm mt-1">
            {errors.phoneNumber.message}
          </p>
        )}
      </div>
      <div className="flex gap-2 transition-all duration-300 ease-in-out items-center justify-start">
        <div className="inline-flex  pt-4 items-center">
          <label
            className="flex items-center cursor-pointer relative"
            htmlFor="taxIdCheckbox"
          >
            <input
              type="checkbox"
              onChange={() => {
                setShowTaxId(!showTaxId);
                resetField("taxId");
              }}
              checked={showTaxId}
              className="peer h-4 w-4 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-border-primary checked:bg-accent checked:border-accent"
              id="taxIdCheckbox"
            />
            <span className="absolute dark:text-[#0d0d0d] text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="1"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </label>
          <label
            className="cursor-pointer ml-2 font-medium text-text-secondary text-sm"
            htmlFor="taxIdCheckbox"
          >
            {t("customerForm.taxId.checkbox")}
          </label>
        </div>
      </div>

      {showTaxId && (
        <div className="mb-4 mt-4 transition-all duration-300 ease-in-out">
          <label htmlFor="state" className="form-label">
            {t("customerForm.taxId.label")}
          </label>
          <input
            {...register("taxId")}
            type="text"
            id="taxId"
            placeholder={t("customerForm.taxId.placeholder")}
            className="form-input"
          />

          {errors.taxId && (
            <p className="text-red-600 text-sm mt-1">{errors.taxId.message}</p>
          )}
        </div>
      )}

      {/* Submit Button */}
      <button
        type="submit"
        id="step1"
        className="w-full flex items-center justify-center py-2 px-4 font-normal 
                 hover:text-black border border-transparent hover:border-border-secondary 
                 mt-6 bg-button-primary-bg dark:text-black font-display text-white 
                 rounded-lg shadow-sm hover:bg-accent focus:outline-none 
                 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        disabled={loading}
      >
        {loading ? <Loading /> : t("customerForm.submit")}
      </button>
    </form>
  );
};

export default CustomerForm;
