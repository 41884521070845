import { Money, PencilSimple, X } from "@phosphor-icons/react";
import * as Dialog from "@radix-ui/react-dialog";
import { Product } from "./CheckoutProductInfo";
import { useForm } from "react-hook-form";
import { decodeCurrency, formatCurrency } from "../lib/utils/parsecurrency";
import { CurrencyCode } from "../lib/utils/parsecurrency";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

interface PayWhatYouWantProps {
  setAmount: (amount: number) => void;
  quantity: number;
  product: Product;
  currency: string;
  showByDefault?: boolean;
}

const ProductCard = ({
  product,
  quantity,
}: {
  product: Product;
  currency: string;
  quantity: number;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-start gap-3">
      <div className="aspect-square rounded-lg w-[60px] h-[60px] relative">
        <img
          alt={product.product_name}
          src={product.product_image}
          className="object-cover rounded-lg w-full h-full"
        />
      </div>
      <div className="flex flex-col gap-1 flex-1 min-w-0">
        <span className="text-text-primary font-display text-[14px] font-medium truncate">
          {product.product_name}
        </span>
        <span className="text-text-secondary font-normal text-[12px] line-clamp-2">
          {product.product_description}
        </span>
      </div>
      <span className="text-text-secondary shrink-0 font-body text-[12px]">
        {t('payWhatYouWant.quantity', { count: quantity })}
      </span>
    </div>
  );
};

const PayWhatYouWant = ({
  setAmount,
  product,
  quantity,
  currency,
  showByDefault = false,
}: PayWhatYouWantProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(showByDefault);
  const { t } = useTranslation();

  if (!product.pay_what_you_want) return null;
  const onSubmit = (data: any) => {
    const newAmount = parseFloat(data.set_price);
    const amountInSmallestUnit = Math.round(newAmount * 100);
    if (amountInSmallestUnit >= product.price) {
      setAmount(amountInSmallestUnit);
      setIsOpen(false);
    } else {
      toast.error(t('payWhatYouWant.amountError'));
    }
  };

  // Convert price to display currency for validation and placeholder
  const minPrice = decodeCurrency(product.price, currency as CurrencyCode);
  const suggestedPrice = product.suggested_price
    ? decodeCurrency(product.suggested_price, currency as CurrencyCode)
    : minPrice;

  return (
    <Dialog.Root onOpenChange={setIsOpen} open={isOpen}>
      <Dialog.Trigger className="p-0">
        <div className="font-semibold w-fit font-display text-text-primary">
          <PencilSimple className="w-4 h-4" />
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 w-full h-full bg-background-primary/90 backdrop-blur-sm" />
        <Dialog.Content
          onOpenAutoFocus={(event) => event.preventDefault()}
          className="fixed top-1/2 left-1/2 w-[90vw] max-w-[480px] transform -translate-x-1/2 -translate-y-1/2 bg-background-primary border border-border-primary p-4 rounded-xl shadow-lg"
        >
          <div className="flex flex-col w-full gap-4">
            <header className="flex flex-col gap-2">
              <div className="flex justify-between w-full items-center">
                <div className="p-2 bg-background-secondary rounded-full">
                  <Money className="text-text-primary w-4 h-4" />
                </div>
                <Dialog.Close className="w-fit h-fit p-0">
                  <X className="w-4 h-4 text-text-primary" />
                </Dialog.Close>
              </div>
              <Dialog.Title className="text-text-primary font-display text-[18px] font-medium">
                {t("payWhatYouWant.enterYourPreferredAmount")}
              </Dialog.Title>
              <Dialog.Description className="text-text-secondary font-normal text-[12px]">
                {t('payWhatYouWant.description')}
              </Dialog.Description>
            </header>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-4 w-full"
            >
              <ProductCard
                quantity={quantity}
                product={product}
                currency={currency}
              />

              <div className="flex-1">
                <label htmlFor="set_price" className="form-label text-sm">
                  {t('payWhatYouWant.setPrice')} <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <input
                    {...register("set_price", {
                      required: t('payWhatYouWant.priceRequired'),
                      min: {
                        value: minPrice,
                        message: t('payWhatYouWant.amountError'),
                      },
                      validate: (value) =>
                        parseFloat(value) >= minPrice ||
                        t('payWhatYouWant.amountError'),
                    })}
                    type="number"
                    step="0.01"
                    id="set_price"
                    placeholder={suggestedPrice.toString()}
                    className="w-full px-3 text-sm placeholder:text-[#667085] dark:placeholder:text-[#888888] text-text-primary py-2 bg-background-primary border border-border-primary rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-accent focus:border-accent disabled:cursor-not-allowed disabled:opacity-60 pl-5"
                  />
                  <span className="absolute left-2 top-1/2 -translate-y-1/2 text-text-primary">
                    {currency === "USD" ? "$" : "₹"}
                  </span>
                </div>
                <p className="text-text-secondary text-xs mt-1">
                  {t('payWhatYouWant.minimumPrice', {
                    price: formatCurrency(
                      decodeCurrency(product.price, currency as CurrencyCode),
                      currency as CurrencyCode,
                      false
                    )
                  })}
                </p>
                {errors.set_price && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors.set_price.message as string}
                  </p>
                )}
              </div>

              <footer className="flex items-center gap-2">
                <Dialog.Close asChild>
                  <button
                    type="button"
                    className="w-full flex items-center justify-center py-2 px-4 text-sm font-normal 
                     hover:text-button-secondary-text-hover border border-transparent hover:border-border-secondary 
                     bg-button-secondary-bg text-button-secondary-text font-display 
                     rounded-lg shadow-sm hover:bg-button-secondary-hover focus:outline-none 
                     focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  >
                    {t('payWhatYouWant.cancel')}
                  </button>
                </Dialog.Close>
                <button
                  type="submit"
                  className="w-full flex items-center justify-center py-2 px-4 text-sm font-normal 
                     hover:text-black border border-transparent hover:border-border-secondary 
                     bg-button-primary-bg dark:text-black font-display text-white 
                     rounded-lg shadow-sm hover:bg-accent focus:outline-none 
                     focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  {t('payWhatYouWant.confirm')}
                </button>
              </footer>
            </form>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default PayWhatYouWant;
