import { Globe, CaretDown } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";

const languages = [
  { code: "en", name: "English" }, // English
  { code: "ar", name: "العربية" }, // Arabic
  { code: "ca", name: "Català" }, // Catalan
  { code: "cn", name: "中文" }, // Chinese
  { code: "de", name: "Deutsch" }, // German
  { code: "es", name: "Español" }, // Spanish
  { code: "fr", name: "Français" }, // French
  { code: "he", name: "עברית" }, // Hebrew
  { code: "it", name: "Italiano" }, // Italian
  { code: "ja", name: "日本語" }, // Japanese
  { code: "nl", name: "Nederlands" }, // Dutch
  { code: "pl", name: "Polski" }, // Polish
  { code: "pt", name: "Português" }, // Portuguese
  { code: "sv", name: "Svenska" }, // Swedish
];

function LanguageSwitch() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const language = localStorage.getItem("language");
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    localStorage.setItem("language", langCode);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-1 p-3 pt-[22px] text-text-primary hover:text-text-secondary transition-colors"
        aria-label="Select Language"
      >
        <Globe className="w-5 h-5" />
        <CaretDown className="w-4 h-4" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 py-2 w-[140px] max-h-[300px] overflow-y-auto bg-background-primary border border-border-primary rounded-lg shadow-lg">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              className={`w-full text-sm px-4 py-2 text-left hover:bg-background-secondary transition-colors ${
                i18n.language === lang.code
                  ? "text-text-primary font-medium"
                  : "text-text-secondary"
              }`}
            >
              {lang.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSwitch;
